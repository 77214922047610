import { Box } from '../../shared/Helpers'
import styled from '../../styled'

export const Image = styled(Box)`
  padding-top: 40%;
  position: relative;
  overflow: hidden;
  border-radius: 6px;

  a {
    display: block;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
