import { graphql, StaticQuery } from 'gatsby'
import * as React from 'react'

import View from './view'

export default function queryFeatureBlog(props: { [key: string]: any }) {
  return (
    <StaticQuery
      query={graphql`
        query FeaturedHeader {
          markdownRemark(frontmatter: { featured: { eq: true } }) {
            excerpt(pruneLength: 150)
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD, YYYY")
              title
              category
              author
              hero {
                childImageSharp {
                  fluid {
                    src
                    srcSet
                    sizes
                  }
                }
              }
            }
          }
        }
      `}
      render={({ markdownRemark: node }) => {
        return (
          <View
            data={{
              ...node.frontmatter,
              excerpt: node.excerpt,
              slug: node.fields.slug,
            }}
          />
        )
      }}
    />
  )
}
