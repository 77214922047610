import { Link } from 'gatsby'
import * as React from 'react'

import { Box } from '../../shared/Helpers'
import Parallax from '../../shared/Parallax'
import BlogCard from '../Card'
import { Image } from './parts'

export interface IProp {
  data: IBlog
}

export default function renderFeature({ data }: IProp) {
  const image = data.hero.childImageSharp

  return (
    <div>
      <Box mb="4em" display={['none', 'block']}>
        {'fluid' in image && (
          <Image>
            <span>
              <Link to={data.slug!}>
                <img {...image.fluid} alt={data.title} />
              </Link>
            </span>
          </Image>
        )}

        <Box
          mt={[null, '-5em', '-10em']}
          ml={[null, '1em', '6em']}
          width={[null, '70%', '43%']}
        >
          <Parallax force={0.3} offsetComp={18}>
            {(offSet: number) => (
              <BlogCard
                onlyCard
                data={data}
                style={{
                  transform: `translateY(${offSet}px) translateZ(0)`,
                }}
              />
            )}
          </Parallax>
        </Box>
      </Box>

      <Box mb="2em" display={['block', 'none']}>
        <BlogCard data={data} />
      </Box>
    </div>
  )
}
