import { graphql } from 'gatsby'
import * as React from 'react'

import Layout from '.'
import BlogFeatured from '../components/Blog/Featured'
import BlogList from '../components/Blog/List'
import BlogNav from '../components/Blog/Nav'
import Pagination from '../components/Blog/Pagination'
import Newsletter from '../components/Newsletter'
import SEO from '../components/SEO'
import { Container } from '../components/shared/Grid'
import { MainTitle } from '../components/shared/Typography'
import { IBlog } from '../interfaces/blog'
import { generateCategoryUrl } from '../utils/generateUrl'

export interface ICategory {
  label: string
  url: string
}

const BlogListPage: React.FC<IBlog> = ({ data, pageContext }) => {
  const blogMessage = data.blogYaml.blog.blogMessage
  const { numPages, currentPage } = pageContext
  const categories = Array.isArray(pageContext.allCategories)
    ? pageContext.allCategories
    : []
  const navData = categories.map(n => ({
    label: n,
    url: generateCategoryUrl(n),
  }))

  return (
    <Layout>
      <SEO />
      <Container>
        <BlogNav data={navData}>
          <MainTitle
            fontSize={['1.75rem', '2.5rem']}
            mt={['4rem']}
            mb={['4rem']}
            width={['100%', '80%', '50%']}
          >
            {blogMessage.title}
          </MainTitle>
        </BlogNav>
        {currentPage === 1 && <BlogFeatured />}
        <BlogList posts={data.allMarkdownRemark.edges} />

        <Pagination total={numPages!} current={currentPage!} path="/blog/" />
      </Container>
      <Newsletter />
    </Layout>
  )
}

export default BlogListPage

export const query = graphql`
  query Blog($skip: Int!, $limit: Int!) {
    blogYaml {
      blog {
        blogMessage {
          title
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { category: { ne: null }, featured: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          excerpt(pruneLength: 100)
          fields {
            slug
            videoSnippet {
              thumbnails {
                high {
                  url
                }
              }
              title
              description
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            author
            category
            videoId
            hero {
              childImageSharp {
                fluid {
                  src
                  srcSet
                  sizes
                }
              }
            }
          }
        }
      }
    }
  }
`
